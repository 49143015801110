import React from 'react'
import './Home.css'
import arrow from './arrow.gif'
import Tutor from './tutor.jpg'
import Kids from './kids.jpeg'
import Lads from './lads.jpg'
import Girl from './subject.jpg'

import Navbar from './Navbar.jsx'

import { Link } from 'react-router-dom'
import { useRef } from 'react'


function Home() {

  const ref = useRef();

  return (
    <div id='all'>
      <Navbar />
      <div id='pg1'>
        <div id="hometitles">
          <h2 className='title' id='first' >Better.</h2>
          <h2 className='title' id='second'>Scores.</h2>
          <h2 className='title' id='third'>Now.</h2>
        </div>
        <div id='bg'>
          <img id='arrow' src={arrow} alt="down arrow" />
        </div>
      </div>
      <div id='info'>
        <div id='first' className='row'>
          <img src={Tutor} alt="tutor" />
          <div className='text'>
            <Link to='/staff'><h3>Tutors who know the process</h3></Link>
            <p>We select the most qualified tutors with top-of-the-line scores
              from the best universities, who not only know content like the back of their hand,
              but can enthusiastically teach it to anyone.
            </p>
            <Link to='/staff'><button>Staff</button></Link>
          </div>
        </div>
        <div id='sideways' className='row'>
          <div className='text'>
            <Link to='/staff'><h3>Pupil-Oriented</h3></Link>
            <p>We'll provide complementary reference materials,
              and aid you in pinpointing specfic areas for improvement and developing strategies to build up those skills.
            </p>
            <Link to='/plans'><button>What We Do</button></Link>
          </div>
          <img src={Kids} alt="tutor" />
        </div>
        <div className='row'>
          <img src={Lads} alt="tutor" />
          <div className='text'>
            <Link to='/contact'><h3>Get in Touch</h3></Link>
            <p>We'd love to hear from you! Feel free to reach out to arrange a meeting, or to discuss our strategies, availability, pricing, or anything else that comes to mind.
            </p>
            <Link to='/contact'><button >Contact Us</button></Link>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Home
